import { UPDATE_ITEMS_STATISTICS } from '@/api/items';

const state = {
  gameMode: 'Simple',
  game: null,
  activePlayers: null,
  gameOptions: JSON.parse(localStorage.getItem('gameOptions')) ?? []
};

const getters = {
  getGameMode: state => {
    return state.gameMode;
  },
  getGameOptions: state => state.gameOptions
};

const actions = {
  async quitAndSaveStatistics({ commit }, { games, id }) {
    const sesId = id;
    console.log(games, id, ' games come to stats');
    let source = games;

    games = [];
    let timesObject = {};
    let prevGameResult = null;
    // let prevPrevGameResult = null;
    console.log(source, games, prevGameResult, 'debugggg');
    source.forEach(game => {
      console.log(game, 'game in foreach');
      timesObject[game.id] = { ...timesObject[game.id] };
      console.log(timesObject, 'times object after insection');
      timesObject[game.id][game.hand.name] = {
        time:
          game.time + timesObject[game.id][game.hand.name]?.time || game.time
      };
      if (prevGameResult) {
        if (
          prevGameResult.id == game.id &&
          prevGameResult.hand.name == game.hand.name &&
          prevGameResult.hand.cards != null &&
          game.hand.cards != null &&
          prevGameResult.hand.cards.length == game.hand.cards.length &&
          prevGameResult.hand.cards[0].card == game.hand.cards[0].card &&
          prevGameResult.hand.cards[0].m == game.hand.cards[0].m &&
          prevGameResult.hand.cards[1].card == game.hand.cards[1].card &&
          prevGameResult.hand.cards[1].m == game.hand.cards[1].m
        ) {
          // if you want to aggregate all hands(etc wrong-correct like 2 answers, uncomment below)
          // games.push(game);
          return;
        } else {
          games.push(game);
          prevGameResult = game;
        }
      } else {
        if (!game.result) {
          prevGameResult = game;
        }
        games.push(game);
      }
    });

    console.log(games, 'games send stat');

    console.log('TIMES FINALLY>? ', timesObject);
    const getPlayedDiapasons = (item, index, arr) =>
      arr.findIndex(el => el.id == item.id) == index;
    // function to get needed diapasons (selected and played) by id in .filter below
    console.log('QAS: result games', games);

    // used for group render update
    if (games.length > 0) {
      // if any set played
      let diapasons = games.filter(getPlayedDiapasons);
      // get diapasons objs that were played (seems correct func)
      // then proccess each one
      console.log(diapasons, 'QAS: diapasons');

      let updatedItems = [];
      diapasons.forEach(diapason => {
        // let diapasonGames = games.filter(el => el.id == diapason.id);
        // // get current diapason sets (games) - object with all set data and result (0 or 1)

        let hands = games
          .filter(el => el.id == diapason.id)
          .map(item => {
            console.log(item, 'IITEM');
            return item;
          });

        let DTO = {
          itemId: diapason.id,
          hands: {}
        };

        hands.forEach(({ hand, result, time, dateTime }) => {
          if (!DTO.hands[hand.name]) {
            DTO.hands[hand.name] = { sequence: [] };
          }
          console.log(time, 'dsfsd');
          DTO.hands[hand.name].sequence.push({
            correct: !!result,
            time,
            dateTime
          });
        });

        updatedItems.push(DTO);
      });
      await UPDATE_ITEMS_STATISTICS(updatedItems, sesId);
    }
    commit('FINISH_GAME');
  },

  changeGameOption({ commit }, options) {
    localStorage.setItem('gameOptions', JSON.stringify(options));
    commit('SET_GAME_OPTIONS', options)
  }
};

const mutations = {
  START_GAME(state, data) {
    state.game = data;
  },

  SET_ACTIVE_PLAYERS(state, data) {
    state.activePlayers = data;
  },

  FINISH_GAME: state => {
    state.game = null;
  },

  SET_ACTIVE_MODE(state, value) {
    state.gameMode = value;
  },

  SET_GAME_OPTIONS(state, value) {
    state.gameOptions = value;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
