<template>
  <div
    :class="[
      'range-editor-wrapper',
      {
        'range-editor-wrapper--is-blocked': isBlocked
      }
    ]"
  >
    <slot name="BeforeRangeOptions" />
    <!-- <transition name="fade" mode="out-in" class="containter"> -->

    <ItemRangeOptionsEditor
      key="itemRangeOptionsEditor"
      v-show="isRange"
      ref="itemRangeOptionsEditor"
      :item="rangeForItemOptionsEditor"
      :isReadOnly="isReadOnly || itemHasPurchaseInfo || itemHasSharingId"
      :requestPending="updateItemRequestPending"
      :btnDisabled="startTestDisabled && !updateAvailable"
      :btnLoading="updateItemRequestPending"
      :btnColor="action.btnColor"
      :btnLabel="action.label"
      :btnSaveDisabled="!updateAvailable"
      :btnSaveLoading="updateItemRequestPending"
      :btnSaveColor="actionSaveBtn.btnColor"
      :btnSaveLabel="actionSaveBtn.label"
      :linkedItemsProvider="loadLinkedItems"
      @btnAction="runAction"
      @checkWeightToggle="onCheckWeightToggle"
      @updateItemOptions="onItemOptionsUpdate"
      @linkedItemsChange="onLinkedItemsChange"
      @linkingModeToggle="onLinkingModeToggle"
      @updated="onValueUpdate"
      @activePart="setActiveParticipant"
      @getTempParts="getTempParts"
      @heroPos="setHeroPos"
    />
    <!-- </transition> -->

    <!-- <transition name="fade" mode="out-in" class="containter"> -->
    <ItemGroupOptionsEditor
      key="ItemGroupOptionsEditor"
      v-show="isGroup"
      ref="itemGroupOptionsEditor"
      :item="groupForItemOptionsEditor"
      :isReadOnly="isReadOnly || itemHasPurchaseInfo"
      :isPremiumGroup="itemHasPurchaseInfo"
      :linkedItemsProvider="loadLinkedItems"
      :requestPending="updateItemRequestPending"
      :btnDisabled="startTestDisabled && !updateAvailable"
      :btnLoading="updateItemRequestPending"
      :btnColor="action.btnColor"
      :btnLabel="action.label"
      :btnSaveDisabled="!updateAvailable"
      :btnSaveLoading="updateItemRequestPending"
      :btnSaveColor="actionSaveBtn.btnColor"
      :btnSaveLabel="actionSaveBtn.label"
      @btnAction="runAction"
      @linkedItemsChange="onLinkedItemsChange"
      @linkingModeToggle="onLinkingModeToggle"
      @updateItemOptions="onItemOptionsUpdate"
      @updated="onValueUpdate"
      @faqToggler="faqToggler"
    />
    <!-- </transition> -->

    <SharingExpander
      :item="item"
      v-if="isGroup && $store.state.user.userInfo.sharingHost"
      @updateUserSharingItems="onUpdateUserSharingItems"
    />

    <div
      class="range-table-editor__actions"
      :style="{ alignItems: 'center' }"
      v-if="isGroup"
    >
      <div class="range-table-editor__actions__inputs">
        <div class="range-table-editor__actions__checkboxes">
          <Checkbox
            v-model="showStatistics"
            :label="localization.general.MainPage.ShowStatistics"
          />
        </div>
      </div>

      <div class="range-table-editor__actions__buttons">
        <DropdownMenu
          :header-label="localization.general.MainPage.Clear"
          :items="clearMenuItemsGroup"
          border-color="gray"
          class="range-table-editor__actions__buttons__item"
        />
      </div>
      <FastFaqButton
        @faqToggler="faqToggler"
        :itemsInFaq="['CopyPasteRanges', 'StatisticActions']"
        lightName="mainMenu"
        :style="{ top: '4px !important' }"
      />
    </div>

    <!-- <transition name="fade" mode="out-in"> -->
    <div class="range-table-editor__actions" v-if="isRange">
      <div class="range-table-editor__actions__inputs">
        <RadioGroup
          v-model="selectionMode"
          ref="RadioGroup"
          class="range-table-editor__actions__radio-btns"
        >
          <Radio
            v-for="option in options"
            :key="option.label"
            :label="option.label"
            :value="option.value"
            :disabled="!isHeroActive && option.value === 'test'"
            :class="[
              'range-table-editor__actions__radio-btns__item',
              {
                'range-table-editor__actions__radio-btns__item--disabled':
                  !isHeroActive && option.value === 'test'
              }
            ]"
          />
        </RadioGroup>
<!--        <Checkbox-->
<!--          :style="{ whiteSpace: 'nowrap' }"-->
<!--          v-model="levelMode"-->
<!--          :disabled="!isHeroActive"-->
<!--          :class="{ 'editor__checkbox&#45;&#45;disabled': !isHeroActive }"-->
<!--          :label="localization.general.MainPage.LevelMode"-->
<!--        />-->
        <transition name="fade">
          <LevelsField
            v-if="storedLevelModeOption"
            :maxLevel="maxLevel"
            @getActiveLevel="onGetActiveLevel"
          />
        </transition>

        <div class="range-table-editor__actions__checkboxes">
          <Checkbox
            :disabled="!isHeroActive"
            v-model="showStatistics"
            :class="{ 'editor__checkbox--disabled': !isHeroActive }"
            :label="localization.general.MainPage.ShowStatistics"
          />
        </div>
      </div>

      <div class="range-table-editor__actions__buttons">
        <DropdownMenu
          :header-label="localization.general.MainPage.Table"
          :items="menuItemsForTable"
          border-color="gray"
          class="range-table-editor__actions__buttons__item"
        />

        <DropdownMenu
          :header-label="localization.general.MainPage.Clear"
          :items="clearMenuItems"
          border-color="gray"
          class="range-table-editor__actions__buttons__item"
        />
      </div>
      <FastFaqButton
        @faqToggler="faqToggler"
        :itemsInFaq="['CopyPasteRanges', 'StatisticActions']"
        lightName="mainMenu"
        :style="{ top: '4px !important' }"
      />
    </div>
    <!-- </transition> -->

    <template v-if="itemHasPurchaseInfo">
      <RangeWarning />
    </template>
    <template v-else>
      <WeightSlider
        v-if="isRange"
        ref="weightsSlider"
        :weight="weightsSlider"
        @weightSliderChange="onWeightSliderChange"
        @click.native="resetSliderRange()"
        :isInverse="isInverse"
      />

      <WeightsList
        v-if="isRange"
        ref="weightsList"
        :weights="weights"
        @weightSelected="weightSelected"
        :isInverse="isInverse"
      />
    </template>

    <slot name="AfterRangeOptions" />
    <slot name="BeforeTable" />

    <RangeTableEditor
      ref="rangeTableEditor"
      :actionColors="actionColors"
      :hands="computedRangeHands"
      :is-read-only="
        isGroup || isReadOnly || itemHasPurchaseInfo || itemHasSharingId
      "
      :selectionMode="selectionMode"
      :heroPosition="heroPosition"
      :showStatistics="showStatistics"
      @clearStatistic="onClearStatistics"
      :statistics="computedRangeStatistics"
      :weight="computedSelectedWeights"
      @handsSelect="onHandsSelect"
      @changeMaxLevel="onChangeMaxLevel"
      :isRange="isRange"
      :playLevel="activeLevel"
      @scrollChanges="scrollChanges"
      :activeParticipant="activeParticipant"
      :isActiveLevelMode="levelMode"
      :inverseOrder="$store.getters['user/actionsOrder']"
      :isInverse="isInverse"
      :statisticsOfGroup="statisticsOfGroup"
    />

    <slot name="AfterTable" />

    <RangeSlider
      v-if="isRange"
      ref="rangeSlider"
      :is-read-only="
        !isRange || itemHasPurchaseInfo || isReadOnly || itemHasSharingId
      "
      :maxValue="169"
      :minValue="0"
      :step="0"
      :value="0"
      @rangeSliderChange="onRangeSliderChange"
    />
    <RangeStringParser
      v-if="isRange"
      :disabled="disableRangeStringParser || itemHasPurchaseInfo"
      @rangeStringParse="onRangeStringParse"
      @faqToggler="faqToggler"
    />
  </div>
</template>

<script>
import {
  CLEAR_STATISTICS,
  GET_ITEMS_INFO,
  UPDATE_ITEM_GROUP,
  UPDATE_ITEM_RANGE,
  UPDATE_PREMIUM_ITEM_GROUP
} from '@/api/items';

// import { isWeightValid } from '@/utils/game';
import FastFaqButton from '@/components/FastFaqDialog/FastFaqButton';

import SharingExpander from '@/components/SharingExpander';

import RangeEditorMixin from '../RangeEditorMixin';

import LevelsField from '@/components/LevelsField';

import _ from 'lodash';

export default {
  name: 'DefaultRangeEditor',

  mixins: [RangeEditorMixin],
  components: {
    FastFaqButton,
    LevelsField,
    SharingExpander
  },
  data() {
    return {
      watchForUpdates: false,
      activeParticipant: null,
      isInverse: false,
      isBlink: true,
      tempParts: {
        type: Object
      }
    };
  },
  props: {
    statisticsOfGroup: Array,
    clearStatistic: Function,
    rangesOfGroupWithAllItems: Array,
    handleDisabledButton: Function,
    onUpdateItemRequestPending: Function,
    handleActionButton: Function,
    faqButtonClick: Function,
    isFaqActive: Boolean,
    purchaseInfo: Object,
    clearStatisticsOfRange: Function,
    openNextItem: Function,
    isTabletOrMobile: Boolean
  },
  computed: {
    isHeroActive() {
      return this.activeParticipant === this.heroPosition;
    },
    groupHasNoRanges() {
      return this.isGroup && this.rangesOfGroupWithAllItems.length === 0;
    },
    startTestDisabled() {
      this.$emit(
        'handleDisabledButton',
        this.groupHasNoRanges || this.linkingMode
      );
      return this.groupHasNoRanges || this.linkingMode;
    }
  },

  methods: {
    onUpdateUserSharingItems(users) {
      console.log(this.item, 'upd item: before');
      if (!this.item.sharing) {
        this.item.sharing = {};
        this.item.sharing.Users = [];
      }

      this.item.sharing.Users = users;
      console.log(this.item, 'upd item: after');
    },
    faqToggler(nodes) {
      this.$emit('faqButtonClick', nodes);
    },

    async loadLinkedItems(itemIDs) {
      console.log(
        'Default Range Editor: loadLinkedItems',
        itemIDs,
        typeof itemIDs
      );
      return await GET_ITEMS_INFO(itemIDs);
    },

    async saveLastDiapason() {},
    async startTest() {
      let activePlayer = this.activeParticipant;
      // let hasHands = false;
      if (this?.item?.type === 'Diapason') {
        for (let prop in this.tempDiapason[activePlayer]?.hands) {
          console.log(prop, 'Default Range Editor: PROP IN START TEST');
          let handUsedForTest = this.tempDiapason[activePlayer]?.hands[prop]
            .useForTest;

          if (handUsedForTest) {
            // hasHands = true;
          }
        }

        // if (!hasHands) {
        //   this.$toast.error(
        //     'Select the cells to test the range and press Save button'
        //   );
        //   return;
        // }

        this.$emit('startTest', activePlayer);
      } else {
        this.$emit('startTest');
      }
    },

    async applyChangesToGroup() {
      const itemGroupOptionsEditor = this.$refs.itemGroupOptionsEditor;
      const appliedChangesToGroup = itemGroupOptionsEditor.submit();

      const linksGroup = appliedChangesToGroup.data.linkedItems
        ? {
            items: appliedChangesToGroup.data.linkedItems
          }
        : null;
      let isPremium = this.itemHasPurchaseInfo;
      return new Promise(async (resolve, reject) => {
        if (!appliedChangesToGroup.validated) {
          reject({
            errorCode: 'NotValidated'
          });
          return;
        }

        if (isPremium) {
          console.log('I am premium');
          await UPDATE_PREMIUM_ITEM_GROUP({
            name: appliedChangesToGroup.data.name,
            linksGroup: linksGroup,
            id: this.item.id,
            parentId: this.item.parentId
          })
            .then(() => {
              this.item.name = appliedChangesToGroup.data.name;
              this.item.linksGroup = linksGroup;

              itemGroupOptionsEditor.turnOffLinkingMode();

              resolve(true);
            })
            .catch(error => {
              reject(error);
            });
        } else {
          await UPDATE_ITEM_GROUP({
            name: appliedChangesToGroup.data.name,
            linksGroup: linksGroup,
            id: this.item.id,
            parentId: this.item.parentId
          })
            .then(() => {
              this.item.name = appliedChangesToGroup.data.name;
              this.item.linksGroup = linksGroup;

              itemGroupOptionsEditor.turnOffLinkingMode();

              resolve(true);
            })
            .catch(error => {
              reject(error);
            });
        }
      });
    },
    async applyChangesToRange() {
      //проверка прошли ли изменения в рендж и айтем
      console.log('rabotttt', this);
      const appliedChangesToCurrentItem = this.$refs.rangeTableEditor.applyChanges();
      const itemRangeOptionsEditor = this.$refs.itemRangeOptionsEditor;
      const updatedRange = itemRangeOptionsEditor.submit();

      return new Promise(async (resolve, reject) => {
        if (!appliedChangesToCurrentItem.validated || !updatedRange.validated) {
          reject({
            errorCode: 'NotValidated'
          });
          return;
        }
        // console.log(this.$refs, 'refs this');
        console.log('UPDATED RANGE', updatedRange);
        console.log('appliedChangesToCurrentItem', appliedChangesToCurrentItem);
        let tempPartsToMerge = this.tempParts;
        let tempHandsToMerge = this.tempDiapason;

        console.log(
          tempPartsToMerge,
          tempHandsToMerge,
          'Default Range Editor: temp diapasons to merge'
        );

        // TODO: Нужно перенести в utils, возможно еще понадобится
        const deepMergeHands = (parts, hands) => {
          let finalMerged = {};
          for (let key in parts) {
            finalMerged[key] = _.merge(parts[key], hands[key]);
          }
          return finalMerged;
        };
        console.log(
          deepMergeHands(tempPartsToMerge, tempHandsToMerge),
          'Default Range Editor: function merge'
        );
        let mergedHands = deepMergeHands(tempPartsToMerge, tempHandsToMerge);
        console.log('Default Range Editor: merged hands', mergedHands);
        const payload = updatedRange.data;
        // payload.participants = this.item.diapason.participants; OLD PARTICIPANTS QUERY
        payload.participants = mergedHands;

        console.log(payload.participants, 'prts to upd');
        payload.parentId = this.item.parentId;
        payload.id = this.item.id;
        // payload.hands = appliedChangesToCurrentItem.data.hands; OLD PUT QUERRY
        console.log(payload, 'Default Range Editor: payload');

        await UPDATE_ITEM_RANGE(payload)
          .then(() => {
            this.item.name = updatedRange.data.name;
            this.item.diapason.additionalProperties =
              updatedRange.data.additionalProperties;
            // this.item.diapason.hands = appliedChangesToCurrentItem.data.hands;
            this.item.diapason.participants = payload.participants;
            this.item.linksGroup = updatedRange.data.linksGroup;

            itemRangeOptionsEditor.turnOffLinkingMode();

            resolve(true);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    async clearStatistics() {
      if (this.isRange) {
        // this.item.diapason.statistics.incorrectAnswers = 0;
        // this.item.diapason.statistics.correctAnswers = 0;
        // this.item.statistics.correct = 0;
        // this.item.statistics.incorrect = 0;
        this.$emit('clearStatisticsOfRange');
      } else {
        // this.item.statistics.correct = 0;
        // this.item.statistics.incorrect = 0;

        this.$emit('clearStatisticsOfGroup', this.item.statistics);
      }

      // this.$refs.rangeTableEditor.clearStatistics();

      if (this.item &&
        this.item.diapason &&
        this.item.diapason.statistics) {
        this.item.diapason.statistics = {}
      }

      return await CLEAR_STATISTICS(this.item.id);
    }
  },
  beforeMount() {
    this.watchForUpdates = false;
  },
  updated() {
    this.watchForUpdates = true;
  },

  watch: {
    updateAvailable() {
      this.$emit('isUpdateAvailable', this.updateAvailable);
    },

    item() {
      this.watchForUpdates = false;
      this.updateAvailable = false;
    }
  }
};
</script>

<style lang="scss" src="./DefaultRangeEditor.scss" />
